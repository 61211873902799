<template>
  <div>
    
    <div v-if="top_spocs_summarys.length != 0">
      <div class="top_spoc_heading">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="2"
          >
            <h3 class="card-header">Top 5 Spocs</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="10"
          >
            <date-range-picker
              ref="picker"
              :opens="'left'"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              style="width: 240px"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="false"
              v-model="topSpocdateRange"
            >
            </date-range-picker>
            &nbsp;
            <vs-button
              color="dark"
              icon="search"
              type="gradient"
              @click="searchTopSpocsByDate"
            >
            </vs-button>
            &nbsp;
            <vs-button
              color="dark"
              type="gradient"
              icon="close"
              @click="refreshTopSpocsData"
              title="Refresh"
            ></vs-button>
          </vs-col>
        </vs-row>
      </div>
      <top-spocs :top_spocs_summarys="top_spocs_summarys"></top-spocs>
    </div>

    <overall-trends :overall_trends="overall_trends" v-if="overall_trends.length > 0"></overall-trends>

    <vs-card v-if="student_details.length != 0">
      <sr-spoc-student-connect
        :student_details="student_details"
      ></sr-spoc-student-connect>
      <!-- <student-connect-table :student_details="student_details"></student-connect-table> -->
    </vs-card>

    <div
      v-if="
        key_metrics.length > 0 || Object.keys(visit_calls_object).length > 0
      "
    >
      <vs-card>
        <!-- <h3 class="card-header">KEY METRICS</h3> -->
        <div class="key_matric_heading">
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <h3 class="card-header">KEY METRICS</h3>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="10"
            >
              <date-range-picker
                ref="picker"
                :opens="'left'"
                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                :minDate="null"
                :maxDate="null"
                :singleDatePicker="false"
                :timePicker="false"
                style="width: 240px"
                :timePicker24Hour="true"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :autoApply="false"
                v-model="KeyMetricsdateRange"
              >
              </date-range-picker>
              &nbsp;
              <vs-button
                color="dark"
                icon="search"
                type="gradient"
                @click="searchKeyMetricsByDate"
              >
              </vs-button>
              &nbsp;
              <vs-button
                color="dark"
                type="gradient"
                icon="close"
                @click="refreshKeyMetricsData"
                title="Refresh"
              ></vs-button>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-type="flex"
          vs-w="12"
          style="margin-top: 2%"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-lg="2"
            vs-sm="6"
            vs-xs="5"
            style="margin-right: 8px"
            v-for="(key_metric, index) in key_metrics"
            :key="index"
          >
            <key-metric-card
              :key_metric="key_metric"
              @total-with-city-enrollment="getCityEnrollment"
            >
            </key-metric-card>
          </vs-col>
          <vs-col
            vs-justify="space-between"
            vs-align="center"
            vs-type="flex"
            vs-lg="2"
            vs-sm="6"
            vs-xs="5"
            v-for="(visit_calls, key) in visit_calls_object"
            :key="visit_calls.visits_key"
          >
            <visitors-card
              :visits_key="key"
              :visits_value="visit_calls"
              @show-calls-city-summary="getCallsCitySummary"
            ></visitors-card>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>

    <vs-card
      style="margin-top: 2%"
      v-if="evaluation_values.length != 0 || evaluation_time_periods.length != 0"
    >
      <h3 class="card-header">Game Plan Stat</h3>
      <vs-row style="margin-top: 2%" vs-type="flex" vs-justify="center">
        <vs-col
          vs-type="flex"
          vs-justify="space-around"
          vs-lg="2"
          vs-sm="4"
          vs-xs="5"
          v-for="(evaluation_value, key, index) in evaluation_values"
          :key="index"
        >
          <evaluation-card
            :evaluation_key="key"
            :evaluation_value="evaluation_value"
            @show-evaluation-details="getEvaluationDetails"
          >
          </evaluation-card>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-w="7">
          <h3 style="margin-bottom: 4px">Game Plan Stat Time Period Details</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-w="5" vs-justify="flex-end" style="margin-bottom: 5px">
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            style="width: 200px; margin-top: 3px"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
          >
          </date-range-picker>
          &nbsp;
          <vs-button color="primary" icon="search" @click="searchEvalutionByDate">
          </vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%" vs-type="flex" vs-justify="center">
        <vs-col
          vs-type="flex"
          vs-justify="space-around"
          vs-lg="2"
          vs-sm="4"
          vs-xs="5"
          v-for="(evaluation_time_period, key, index) in evaluation_time_periods"
          :key="index"
        >
          <evaluation-time-period-card
            :evaluation_time_period="evaluation_time_period"
            :evaluation_time_period_key="key"
            @show-evaluation-time-period-details="getEvaluationTimePeriodDetails"
          >
          </evaluation-time-period-card>
        </vs-col>
      </vs-row>
    </vs-card>

    <!-- Bridge Course -->
    <vs-card
      v-if="eligibility_counts.length != 0 || eligibility_time_period_counts.length != 0"
    >
      <h3 class="card-header">Bridge Course</h3>
      <vs-row style="margin-top: 2%" vs-type="flex" vs-justify="center">
        <vs-col
          vs-type="flex"
          vs-justify="space-around"
          vs-lg="2"
          vs-sm="4"
          vs-xs="5"
          v-for="(eligibility_count, key, index) in eligibility_counts"
          :key="index"
        >
          <bridge-course-card
            :eligibility_count="eligibility_count"
            :eligibility_key="key"
            @show-eligibility-details="getSpocEligibilityDetails"
          ></bridge-course-card>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-w="7">
          <h3 style="margin-bottom: 4px">Bridge Course Time Period Details</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-w="5" vs-justify="flex-end" style="margin-bottom: 5px">
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            style="width: 200px; margin-top: 3px"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRangeEligibility"
          >
          </date-range-picker>
          &nbsp;
          <vs-button color="primary" icon="search" @click="searchSpocEligibilityByDate">
          </vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%" vs-type="flex" vs-justify="center">
        <vs-col
          vs-type="flex"
          vs-justify="space-around"
          vs-lg="2"
          vs-sm="4"
          vs-xs="5"
          v-for="(
            eligibility_time_period_count, key, index
          ) in eligibility_time_period_counts"
          :key="index"
        >
          <bridge-course-time-period-card
            :eligibility_time_period_count="eligibility_time_period_count"
            :eligibility_time_period_key="key"
            @show-eligibility-time-period-details="getSpocEligibilityTimePeriodDetails"
          >
          </bridge-course-time-period-card>
        </vs-col>
      </vs-row>
    </vs-card>
    <enrollments-popup :enrollments_popup="enrollments_popup" :city_wises_enrollments="city_wises_enrollments"
        :date_props="date_props" @close-enrollments-popup="enrollments_popup = false; this.selected_course = ''">
      </enrollments-popup>
      <visit-count-popup
      :visits_city_summary_popup="visits_city_summary_popup"
      :visits_city_wises="visits_city_wises"
      :date_props="date_props"
      @close-visit-count-popup="visits_city_summary_popup = false"
    ></visit-count-popup>
    <ConnectedCallsPopup :calls_city_summary_popup="calls_city_summary_popup" :calls_city_wises="calls_city_wises"
        :date_props="date_props" @close-connected-call-popup="calls_city_summary_popup = false"></ConnectedCallsPopup>
  </div>
</template>

<script>
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
// import constants from "../../../constants.json";
// import StudentConnectTable from '../../components/SrDashboard/StudentConnectTable.vue'
import SrSpocStudentConnect from "../../components/SrSpocDashboard/SrSpocStudentConnect.vue";
import EvaluationCard from "../../components/SrDashboard/EvaluationCard.vue";
import EvaluationTimePeriodCard from "../../components/SrDashboard/EvaluationTimePeriodCard.vue";
import ApiServices from "../../ApiServices";
import BridgeCourseCard from "../../components/SrDashboard/BridgeCourseCard.vue";
import BridgeCourseTimePeriodCard from "../../components/SrDashboard/BridgeCourseTimePeriodCard.vue";
// import TopSpocs from "../../components/CmDashboard/TopSpocsSummary.vue";
import TopSpocs from "../../components/SrDashboard/SRTopSpocs.vue"
import OverallTrends from "../../components/CmDashboard/OverallTrends.vue";
import KeyMetricCard from "../../components/CmDashboard/keyMetricCard.vue";
import VisitorsCard from "../../components/CmDashboard/VisitorsCard.vue";
import ConnectedCallsPopup from "../../components/CmDashboard/ConnectedCallsPopup.vue";
import EnrollmentsPopup from "../../components/CmDashboard/EnrollmentsPopup.vue";
import VisitCountPopup from "../../components/CmDashboard/VisitCountPopup.vue";
export default {
  components: {
    SrSpocStudentConnect,
    // StudentConnectTable,
    EvaluationCard,
    EvaluationTimePeriodCard,
    BridgeCourseCard,
    BridgeCourseTimePeriodCard,
    DateRangePicker,
    OverallTrends,
    TopSpocs,
    KeyMetricCard,
    VisitorsCard,
    ConnectedCallsPopup,
    VisitCountPopup,
    EnrollmentsPopup,
  },
  data: () => ({
    open: false,
    dateRange: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    student_details: [],
    evaluation_values: [],
    evaluation_time_periods: [],
    top_spocs_summarys: [],
    calls_city_summary_popup: false,
    enrollments_popup: false,
    key_metrics:[],
    visits_city_wises:[],
    visits_city_summary_popup:false,
    overall_trends: [],
    city_wises_enrollments:[],
    date_props: [],
    calls_city_wises:[],
    visit_calls_object:{},
    selected_course:"",
    selected_gm:"",
    selected_spoc:"",
    dateRangeEligibility: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    KeyMetricsdateRange: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    topSpocdateRange:{
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    eligibility_counts: [],
    eligibility_time_period_counts: [],
  }),
  mounted() {
    this.getEvaluetionCount();
    this.getStudentConnectStats();
    let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("DD-MM-YYYY");
    let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("DD-MM-YYYY");
    let startDateEligibility = moment(
      this.dateRangeEligibility.startDate,
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    let endDateEligibility = moment(
      this.dateRangeEligibility.endDate,
      "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    this.getEvaluationTimePeriodCount(startDate, endDate);
    this.getSpocEligibilityCount();
    this.getSpocTimeperiodEligibility(startDateEligibility, endDateEligibility);
    this.getTopSpocs();
    this.getOverallTrendsData();
    this.getEnrollmentData();
    this.getVisitsData();
  },
  methods: {
    getEvaluetionCount() {
      this.$vs.loading();
      // let url = `https://milesdashboards.2x2.ninja/api/spocEvaluationCount`
      ApiServices.spocEvaluationCount()
        .then((response) => {
          this.evaluation_values = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    getCityEnrollment(course) {
      // console.log("course--", course);
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.netenquiry_city_wises = [];
      if (course == null) {
        this.selected_course = "";
      } else {
        this.selected_course = course;
      }
      // console.log("course2--", course);
      this.$vs.loading();
      let startDate = moment(this.KeyMetricsdateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.KeyMetricsdateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
        course: this.selected_course,
      };
      if (this.selected_course != "") {
        this.date_props = {
          card_header:
            startDate + " - " + endDate + " | " + this.selected_course,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: this.selected_course
        };
        // console.log("this.date_props course not empty", this.date_props)
      } else {
        this.date_props = {
          card_header: startDate + " - " + endDate,
          gm: this.selected_gm,
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: ""
        };
        // console.log("this.date_props course empty", this.date_props)
      }
      ApiServices.getCityEnrollment(startDate, endDate, obj)
        .then((response) => {
          // console.log("Find Table Data", response.data);
          this.city_wises_enrollments = response.data;
          this.enrollments_popup = true;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getOverallTrendsData() {
      console.log("gm", this.selected_gm);
      console.log("cm", this.selected_spoc);
      this.$vs.loading();
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-trend-summary/`;
      // if (this.selected_gm != null && this.selected_gm != "") {
      //   url += `?gm=${this.selected_gm}`;
      // } else if (this.selected_spoc != null && this.selected_spoc != "") {
      //   url += `?spoc=${this.selected_spoc}`;
      // }
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Token 0d03c8c040170a8a621e1a0edbe73ad23bf158e2`,
      //     },
      //   })
      ApiServices.getOverallTrendsSummary(this.selected_gm,this.selected_spoc,"SR")
        .then((response) => {
          console.log("getOverallTrendsSummary", response.data);
          this.overall_trends = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getVisitsData() {
      this.$vs.loading();
      let startDate = moment(this.KeyMetricsdateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.KeyMetricsdateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      // console.log("start_date", startDate);
      // console.log("end_date", endDate);
      // console.log("selected_spoc", this.selected_spoc);
      // console.log("selected_gm", this.selected_gm);
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-visits-calls/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallVisitsCalls?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getVisitsData(startDate, endDate, obj)
        .then((response) => {
          let visits = response.data;
          // console.log("visits", visits);
          let check_if_array = Array.isArray(visits);
          let visit_call = {};
          if (check_if_array) {
            for (let i = 0; i < visits.length; i++) {
              visit_call = visits[0];
              // this.key_metrics.push(element);
              console.log("key metrics object", visit_call);
            }
            this.visit_calls_object = {
              connected_calls: {
                count: visit_call["connected_calls"],
                utilization: visit_call["call_utilization"],
              },
              visit_count: {
                count: visit_call["visit_count"],
                utilization: visit_call["visit_utilization"],
              },
            };
            // console.log("visit_calls_object", this.visit_calls_object);
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    searchTopSpocsByDate(){
      this.top_spocs_summarys = [];
      this.getTopSpocs();
    },
    refreshTopSpocsData() {
      this.topSpocdateRange ={
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
    },
    this.getTopSpocs();
    },
    searchKeyMetricsByDate() {
      this.key_metrics = [];
      this.visit_calls_object = [];
      this.getEnrollmentData();
      this.getVisitsData();
    },
    refreshKeyMetricsData() {
      this.KeyMetricsdateRange = {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
      };
      this.getEnrollmentData();
      this.getVisitsData();
    },
    getCallsCitySummary(val) {
      this.$vs.loading();
      let startDate = moment(this.KeyMetricsdateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.KeyMetricsdateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        gm: this.selected_gm,
        spoc: this.selected_spoc,
      };
      if (val == "connected_calls") {
        this.city_wises_enrollments = [];
        this.visits_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/calls-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getCallsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm_name}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getCallsCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.calls_city_wises = response.data;
            this.calls_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
      if (val == "visit_count") {
        this.city_wises_enrollments = [];
        this.calls_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        this.netenquiry_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/visits-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getVisitsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getVisitsCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.visits_city_wises = response.data;
            this.visits_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getEnrollmentData() {
      this.course_wises = [];
      this.key_metrics = [];
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        gm: this.selected_gm,
      };
      // console.log("start_date", startDate);
      // console.log("end_date", endDate);
      // console.log("selected_spoc", this.selected_spoc);
      // console.log("selected_gm", this.selected_gm);
      // let url = `${constants.MILES_DASHBOARDS}getOverallEnrollmentsCount?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // // let url = `http://milesapi.bulbot.com/api/performance-dashboard/enrollments-overall-count/?end-date=${endDate}&start-date=${startDate}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getEnrollmentData(startDate, endDate, obj)
        .then((response) => {
          let enrollment = response.data;
          // console.log("enrollment", enrollment);
          let check_if_array = Array.isArray(enrollment);
          if (check_if_array) {
            for (let i = 0; i < enrollment.length; i++) {
              const element = enrollment[i];
              if (element.course == "Total enrollments") {
                this.key_metrics.push(element);
              } else if (element.course != "Total enrollments") {
                this.course_wises.push(element);
              } else {
                this.key_metrics = [];
                this.course_wises = [];
              }
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getTopSpocs() {
      let startDate = moment(this.topSpocdateRange.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      let endDate = moment(this.topSpocdateRange.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      ApiServices.getTopSpocs(startDate, endDate).then((response) => {
        // console.log("getTopSpocs", response.data)
        if (typeof response.data == 'object') {
          this.top_spocs_summarys = response.data
          console.log("getTopSpoc", this.top_spocs_summarys)
        }
      })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getStudentConnectStats() {
      let url = "https://milesdashboards.2x2.ninja/api/spocStudentConnects";
      this.$vs.loading();
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log("getStudentConnectStats",response.data)
          this.student_details = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    getEvaluationDetails(type) {
      let evaluation_header = {
        evaluation_submitted: "Evaluation Submitted Details",
        completed_evaluation: "Evaluation Completed Details",
        evaluation_initiated: "Evaluation Initiated Details",
        evaluation_not_initiated: "Evaluation Not Initiated Details",
        total_evaluations: "Total Evaluation Details",
      };
      let evaluation_detail = {
        evaluation_type: type,
        evaluation_header: evaluation_header[type],
      };
      this.$router.push({
        name: "SrSpocCountDetails",
        params: { evaluation_detail: evaluation_detail },
      });
      console.log("evaluation ");
    },
    getEvaluationTimePeriodCount(startDate, endDate) {
      this.evaluation_time_periods = [];
      this.$vs.loading();
      // let url = `${constants.MILES_DASHBOARDS}timeperiodspocEvaluationCount?from_date=${startDate}&to_date=${endDate}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
      ApiServices.timeperiodspocEvaluationCount(startDate, endDate)
        .then((response) => {
          this.evaluation_time_periods = response.data;
          // console.log("Time Period", this.evaluation_time_periods)
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    searchEvalutionByDate() {
      this.evaluation_time_periods = [];
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("DD-MM-YYYY");
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("DD-MM-YYYY");
      this.getEvaluationTimePeriodCount(startDate, endDate);
    },
    getEvaluationTimePeriodDetails(type) {
      let evaluation_header = {
        evaluation_submitted: "Evaluation Submitted Details",
        completed_evaluation: "Evaluation Completed Details",
        evaluation_initiated: "Evaluation Initiated Details",
        evaluation_not_initiated: "Evaluation Not Initiated Details",
        total_evaluations: "Total Evaluation Details",
      };
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("DD-MM-YYYY");
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("DD-MM-YYYY");
      let time_period_detail = {
        evaluation_type: type,
        evaluation_header: evaluation_header[type],
        start_date: startDate,
        end_data: endDate,
      };
      this.$router.push({
        name: "SpocTimePeriodDetails",
        params: { time_period_detail: time_period_detail },
      });
      console.log("getEvaluationTimePeriodDetails", type, startDate, endDate);
    },
    getSpocEligibilityCount() {
      this.$vs.loading();
      ApiServices.spocEligibilityCount()
        .then((response) => {
          this.eligibility_counts = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    getSpocEligibilityDetails(type) {
      let eligibility_header = {
        missed_enrollment: "Missed Eligibility ",
        completed_enrollment: "Completed Eligibility ",
        total_eligibility: "Total Eligibility",
      };
      let eligibility_detail = {
        eligibility_type: type,
        eligibility_header: eligibility_header[type],
      };
      this.$router.push({
        name: "SrSpocBridgeCourseDetails",
        params: { eligibility_detail: eligibility_detail },
      });
    },
    getSpocTimeperiodEligibility(startDate, endDate) {
      this.$vs.loading();
      ApiServices.timeperiodSpocEligibilityCount(startDate, endDate)
        .then((response) => {
          this.eligibility_time_period_counts = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.$vs.loading.close();
        });
    },
    searchSpocEligibilityByDate() {
      this.eligibility_time_period_counts = [];
      let startDateEligibility = moment(
        this.dateRangeEligibility.startDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      let endDateEligibility = moment(
        this.dateRangeEligibility.endDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      this.getSpocTimeperiodEligibility(startDateEligibility, endDateEligibility);
    },
    getSpocEligibilityTimePeriodDetails(type) {
      let eligibility_header = {
        missed_enrollment: "Missed Eligibility ",
        completed_enrollment: "Completed Eligibility ",
        total_eligibility: "Total Eligibility",
      };
      let startDateEligibility = moment(
        this.dateRangeEligibility.startDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      let endDateEligibility = moment(
        this.dateRangeEligibility.endDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");
      let eligibility_time_period_detail = {
        eligibility_type: type,
        eligibility_header: eligibility_header[type],
        start_date: startDateEligibility,
        end_date: endDateEligibility,
      };
      this.$router.push({
        name: "SrBridgeCourseTimePeriodDetails",
        params: { eligibility_time_period_detail: eligibility_time_period_detail },
      });
    },
  },
};
</script>
<style scoped>
.card-header {
  text-align: start;
  background: #0044ba;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.key_matric_heading {
  background: #0044ba;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
}
.top_spoc_heading{
  background: #0044ba;
  border-radius: 15px 15px 0px 0px;
  padding-block: 10px;
}
</style>
