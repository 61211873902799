<template>
  <div>
  <vs-card >
    <div slot="header">
      <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
            <h3>
              Student Connects
            </h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <v-select v-model="selected_year" :options="years" placeholder="Select Year" style="width: 240px"></v-select>
          </vs-col>
        </vs-row>
      </div>
    <vs-table stripe :data="student_data">
      <template slot="thead">
        <vs-th>
          Levels
        </vs-th>
        <vs-th v-if="show_student_connect">
          Connected Last 30D
        </vs-th>
        <vs-th v-if="show_student_connect">
          Connected 90D
        </vs-th>
        <vs-th v-if="show_student_connect">
          Connected 180D
        </vs-th>
        <vs-th v-if="show_student_connect">
          Connected 180D+
        </vs-th>
        <vs-th v-if="show_student_connect">
          Not Connected
        </vs-th>
        <vs-th v-if="show_year">
            Year Data
          </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].level">
            {{ level_name[data[indextr].level] }}
          </vs-td>

          <vs-td :data="data[indextr].last_30_days" v-if="show_student_connect">
            <div @click="getStudentConnectDetails(data[indextr].level, 'last_30_days')"> {{ data[indextr].last_30_days
            }}
            </div>
          </vs-td>
          <vs-td :data="data[indextr].last_90_days" v-if="show_student_connect">
            <div @click="getStudentConnectDetails(data[indextr].level, 'last_90_days')"> {{ data[indextr].last_90_days
            }}
            </div>
          </vs-td>
          <vs-td :data="data[indextr].last_180_days" v-if="show_student_connect">
            <div @click="getStudentConnectDetails(data[indextr].level, 'last_180_days')">
              {{ data[indextr].last_180_days }} </div>
          </vs-td>
          <vs-td :data="data[indextr].before_180_days" v-if="show_student_connect">
            <div @click="getStudentConnectDetails(data[indextr].level, 'before_180_days')">
              {{ data[indextr].before_180_days }} </div>
          </vs-td>
          <vs-td :data="data[indextr].not_connected" v-if="show_student_connect">
            <div @click="getStudentConnectDetails(data[indextr].level, 'not_connected')">
              {{ data[indextr].not_connected }} </div>
          </vs-td>
          <vs-td :data="data[indextr].year_data" v-if="show_year">
              <div @click="getStudentConnectDetails(data[indextr].level, year_heading)">
                {{ data[indextr].year_data }} </div>
            </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </vs-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  props:["student_details"],
  components: {
    "v-select": vSelect
  },
  mounted(){
     this.student_data = this.student_details
  },
  watch:{
    selected_year(val){
       this.selected_year_data = [];
      const year = "year_" + val
      console.log("selected",year)
      this.year_heading = year
      for (let i = 0; i < this.student_details.length; i++) {
        const element = this.student_details[i];
        const obj = {
          "level":element.level,
          "year_data":element[year]
        }
        if(val == null || val == undefined){
          this.show_year = false;
        this.show_student_connect = true;
        this.selected_year_data.push(element)
      }else{
        this.selected_year_data.push(obj)
        this.show_year = true;
        this.show_student_connect = false;
      }
      }
      this.student_data = this.selected_year_data
    }
  },
  data: () => ({
    selected_year_data:[],
    show_year:false,
    year_heading:'',
    show_student_connect:true,
    student_data:[],
    open: false,
    selected_year:null,
    dateRange: {
      startDate: "2021-01-01",
      endDate: "2021-06-30",
    },
    years: [
      "2021",
      "2022"
    ],
     level_name: {
      "M7": "M7 Enrolled (Within 7 days)",
      "M8": "M8 Evaluation (30-60 days) - IMA Regt(CMA)",
      "M9": "M9 One exam cleared (30-60 days)",
      "M10": "M10 Alumni (30 days)",
      "M7+": "M7+LMS demo completed (30-60 days)",
      "M7-": "M7-Drop-out (30 days)",
      "M7X": "M7X - Do Not Ever Call (NFD - Blank)",
      "M8-": "M8-Drop-out (after evaluation) (once every 3 months)",
      "M9-": "M9- Drop-out (after clearing an exam) (once every 3 months)",
      "M8+": "M8+ NTS received/Exam regt done",
      "M7#": "M7# - Onboarding Done",
      "M7-JJ": "M7-JJ (Non Miles Jain Enrolled Candidates)",
      "M7D1": "M7D1 Defaulter",
      "M7D2":"M7D2 Loan Defaulter"
    }
   
  }),
  methods: {
    getStudentConnectDetails(level,type) {
      let type_header = {
        'last_30_days': "Connected Last 30 Days",
        'last_90_days': "Connected Last 90 Days",
        'last_180_days': "Connected Last 180 Days",
        'before_180_days': "Connected 180+ Days",
        'not_connected': "Not Connected",
        'year_2021': "Year 2021",
        'year_2022': "Year 2022",
      }
      let level_day = {
        "level": level,
        "type": type,
        "type_header": type_header[type],

      }
      this.$router.push({ name: "SrSpocStudentConnectDetails", params: { studentConnect:level_day } });
    },
  }
}
</script>